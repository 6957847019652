type TConfig = {
  common: string[];
  feature: string;
};

export const playButtonTranslationConfig: TConfig = {
  common: ['Common.VisitGame'],
  feature: ''
};

export default playButtonTranslationConfig;
