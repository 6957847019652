import { EnvironmentUrls, IdentityVerificationService } from 'Roblox';
import { deviceMeta as DeviceMeta, jsClientDeviceIdentifier } from 'header-scripts';
import { playGameService } from 'core-roblox-utilities';
import playButtonConstants from '../constants/playButtonConstants';

export const launchGame = (
  placeId: string,
  eventProperties: Record<string, string | number | undefined> = {}
): void => {
  const deviceMeta = DeviceMeta.getDeviceMeta();
  if (
    deviceMeta?.isIosDevice ||
    deviceMeta?.isAndroidDevice ||
    jsClientDeviceIdentifier.isIos13Ipad
  ) {
    const universalLink = `${EnvironmentUrls.websiteUrl}/games/start?placeid=${placeId}`;
    const encodedUniversalLink = encodeURIComponent(universalLink);
    window.open(
      `https://ro.blox.com/Ebh5?pid=share&is_retargeting=true&af_dp=${encodedUniversalLink}&af_web_dp=${encodedUniversalLink}`,
      '_self'
    );
  } else {
    playGameService.launchGame(
      playGameService.buildPlayGameProperties(undefined, placeId.toString()),
      playButtonConstants.eventStreamProperties(placeId, eventProperties)
    );
  }
};

export const launchLogin = (placeId: string): void => {
  const deviceMeta = DeviceMeta.getDeviceMeta();
  if (
    deviceMeta?.isIosDevice ||
    deviceMeta?.isAndroidDevice ||
    jsClientDeviceIdentifier.isIos13Ipad
  ) {
    const universalLink = `${EnvironmentUrls.websiteUrl}/games/start?placeid=${placeId}`;
    const encodedUniversalLink = encodeURIComponent(universalLink);
    window.open(
      `https://ro.blox.com/Ebh5?pid=share&is_retargeting=true&af_dp=${encodedUniversalLink}&af_web_dp=${encodedUniversalLink}`,
      '_self'
    );
  } else {
    playGameService.launchGame(
      playGameService.buildPlayGameProperties(undefined, placeId.toString()),
      playButtonConstants.eventStreamProperties(placeId, {})
    );
  }
};

export const startVerificationFlow = async (): Promise<[boolean, boolean]> => {
  try {
    return IdentityVerificationService.startVerificationFlow();
  } catch (e) {
    return [false, false];
  }
};

export const startVoiceOptInOverlayFlow = async (): Promise<boolean> => {
  try {
    return IdentityVerificationService.showVoiceOptInOverlay();
  } catch (e) {
    return false;
  }
};

export default {
  launchGame,
  launchLogin,
  startVerificationFlow,
  startVoiceOptInOverlayFlow
};
